<!--首页-->
<template>
    <div>
        <div style="padding: 20px 0;overflow-x: hidden;" v-if="isPc">
            <!-- pc端 -->
            <!--导航-->
            <div class="main"
                style="border-radius: 14px;background: #fff;margin-bottom:20px;display:flex;flex-direction:column">
                <div class="title">
                    <div></div>
                    <div style="display:flex;justify-content: space-between;width: 95%;align-items: center;">
                        <div>数据总览</div>
                        <div style="display: flex;align-items: center;">
                            <div>时间范围</div>
                            <el-date-picker v-model="timeScope" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                style="margin: 0 20px;">
                            </el-date-picker>
                            <el-button type="primary" @click="search">确定</el-button>
                        </div>
                    </div>
                </div>
                <div style="display:flex;justify-content:space-around;padding-bottom: 20px;">
                    <div
                        style="display:flex;flex-direction:column;position:relative;background-image:url('https://sihuankangyuan.cn/user/resource/icon/Pctitle1%20.png');background-size:100% 100%;width:23%;height:163px;color:#fff;justify-content: space-around;">
                        <div style="font-size:48px;margin-left:25px">{{ datas.count }}</div>
                        <div style="font-size:18px;margin-left:25px">总用户量</div>
                    </div>
                    <div
                        style="display:flex;flex-direction:column;position:relative;background-image:url('https://sihuankangyuan.cn/user/resource/icon/Pctitle2.png');background-size:100% 100%;width:23%;;height:163px;color:#fff;justify-content: space-around;">
                        <div style="font-size:48px;margin-left:25px">{{ datas.vipCount }}</div>
                        <div style="font-size:18px;margin-left:25px">会员数量</div>
                    </div>
                    <div
                        style="display:flex;flex-direction:column;position:relative;background-image:url('https://sihuankangyuan.cn/user/resource/icon/Pctitle3.png');background-size:100% 100%;width:23%;;height:163px;color:#fff;justify-content: space-around;">
                        <div style="font-size:48px;margin-left:25px">{{ num }}</div>
                        <div style="font-size:18px;margin-left:25px">学习5小时以上用户</div>
                    </div>
                   
                </div>
                

            </div>
           
            <div class="bottom">
                <div class="bordertitle">
                    <div>
                        <div class="line"></div>
                        <div>用户学习数据</div>
                    </div>
                    <div style="display: flex;align-items: center;font-weight: 400;">
                       
                        <div>排序</div>
                        <el-select v-model="levelSortId" placeholder="按等级" clearable class="cinput ac" @change="list"
                            style="margin-left:20px">
                            <el-option v-for="item in levelSortList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-model="timeId" placeholder="按时长" clearable class="cinput ac" @change="list"
                            style="margin-left:20px">
                            <el-option v-for="item in timeList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="bordertitle">
                   
                    <div style="display: flex;align-items: center;font-weight: 400;">
                        <div style="flex-shrink: 0;">等级</div>
                        <el-select v-model="levelId" placeholder="等级筛选" class="cinput ac" @change="list">
                            <el-option v-for="item in levelList" :key="item.levelId" :label="item.levelName" :value="item.levelId">
                            </el-option>
                        </el-select>
                        <div style="display: flex;align-items: center;">
                            <div style="flex-shrink: 0;">学习时长</div>
                            <el-input v-model="lowestStudyTimeMinute" placeholder="最短时长"></el-input>
                            <div>至</div>
                            <el-input v-model="highestStudyTimeMinute" placeholder="最长时长"></el-input>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center;font-weight: 400;">
                        <div style="flex-shrink: 0;">时间范围</div>
                        <el-date-picker v-model="timeScope2" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                            style="margin: 0 20px;">
                        </el-date-picker>
                        <el-button type="primary" @click="list">确定</el-button>
                    </div>
                </div>
                <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData"
                    style="width: 100%; min-height: 350px">

                    <el-table-column type="index" width="50"> </el-table-column>
                    <el-table-column prop="userName" label="美容师名称" align="center">
                    </el-table-column>
                    <el-table-column prop="levelName" align="center" label="美容师等级" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="storeName" align="center" label="门店" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="studyTimeMinutes" align="center" label="学习时长" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: center" v-if="scope.row.studyMinutes">
                                {{ scope.row.studyMinutes | stime }}
                            </div>
                            <div style="display: flex; justify-content: center" v-if="!scope.row.studyMinutes">
                                0小时0分
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" align="center">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: center">
                                <el-link type="primary" :underline="false" @click='details(scope.row)'>查看详情</el-link>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
              
            </div>

        </div>

        <!-- 手机端 -->
        <div style="padding: 20px 0" v-if="!isPc">
            <!-- 我是首页 -->
            <!--导航-->
            <div class="main"
                style="border-radius: 14px;background: #fff;margin-bottom:20px;display:flex;flex-direction:column">
                <div class="title" style="align-items: flex-start;">
                    <!-- <div style=" width: 9px;height: 22px;border-radius: 10px;background: #80dfff;margin-right: 10px;"></div> -->
                    <div style="display:flex;justify-content: space-between;width: 95%;background: #fff;">
                        <div>数据总览</div>
                        <div style="color:rgba(0, 0, 0, 0.3)" @click="exit">退出</div>
                        <!-- <div style="display: flex;flex-direction: column;">
                            <div>时间范围</div>
                            <el-date-picker v-model="timeScope" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                >
                            </el-date-picker>
                            <el-button type="primary" @click="search" >确定</el-button>
                        </div> -->
                    </div>
                </div>
                <div style="display:flex;justify-content:space-around;padding-bottom: 20px;">
                    <div
                        style="display:flex;flex-direction:column;position:relative;background-image:url('https://user-1308336073.cos.ap-nanjing.myqcloud.com/user/resource/icon/home_red.png');background-size:100% 100%;width:30%;height:70px;color:#fff;justify-content: space-around;">
                        <div style="font-size:18px;margin-left:10px">总用户量</div>
                        <div style="font-size:32px;margin-left:10px">{{ datas.count }}</div>
                    </div>
                    <div
                        style="display:flex;flex-direction:column;position:relative;background-image:url('https://user-1308336073.cos.ap-nanjing.myqcloud.com/user/resource/icon/home_green.png');background-size:100% 100%;width:30%;;height:70px;color:#fff;justify-content: space-around;">
                        <div style="font-size:18px;margin-left:10px">会员数量</div>
                        <div style="font-size:32px;margin-left:10px">{{ datas.vipCount }}</div>
                    </div>
                    <div
                        style="display:flex;flex-direction:column;position:relative;background-image:url('https://user-1308336073.cos.ap-nanjing.myqcloud.com/user/resource/icon/home_blue.png');background-size:100% 100%;width:30%;;height:70px;color:#fff;justify-content: space-around;">
                        <div style="font-size:12px;margin-left:10px">学习5小时以上用户</div>
                        <div style="font-size:32px;margin-left:10px">{{ num }}</div>
                    </div>
                   
                </div>
                

            </div>
           
            <div class="bottom">
                <div class="bordertitle" style="flex-direction: column;">
                    <div style="display: flex;justify-content: space-between;">
                        <!-- <div class="line"></div> -->
                        <div>用户学习数据</div>
                         <div style="display:flex;align-items:center" @click="searchKeywords">
                            <img src="../images/searchHome.png" alt="" style="width:16px;height: 16px;">
                            <div style="font-size:16px;color:rgba(0, 0, 0, 0.3);">搜索与筛选</div>
                        </div>
                    </div>
                    <div style="display: flex;font-weight: 400;flex-direction: column;">
                       
                        <!-- <div>排序</div> -->
                        <!-- <el-select v-model="levelSortId" placeholder="按等级排序" clearable class="cinput ac" @change="list"
                            style="margin-left:20px">
                            <el-option v-for="item in levelSortList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-model="timeId" placeholder="按时长排序" clearable class="cinput ac" @change="list"
                            style="margin-left:20px">
                            <el-option v-for="item in timeList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select> -->
                    </div>
                </div>
                <div >
                    <!-- <div style="display: flex;align-items: center;font-weight: 400;">
                        <div style="flex-shrink: 0;">等级</div>
                        <el-select v-model="levelId" placeholder="品牌筛选" class="cinput ac" @change="list">
                            <el-option v-for="item in levelList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        
                    </div>
                    <div style="display: flex;align-items: center;margin: 20px 0;">
                            <div style="flex-shrink: 0;">时长</div>
                            <el-input v-model="lowestStudyTimeMinute" placeholder="最短时长"></el-input>
                            <div>至</div>
                            <el-input v-model="highestStudyTimeMinute" placeholder="最长时长"></el-input>
                        </div> -->
                    <div style="display: flex;font-weight: 400;justify-content: space-between;margin-bottom: 20px;">
                        <!-- <div style="flex-shrink: 0;">时间范围</div> -->
                        <!-- <el-date-picker v-model="timeScope2" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                            style="margin: 0 20px;">
                        </el-date-picker>
                        <el-button type="primary" @click="list">确定</el-button> -->
                    </div>
                </div>
                <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData"
                    style="width: 100%; min-height: 350px"  @sort-change="levelSort">

                    <el-table-column prop="userName" label="名称" align="center">
                    </el-table-column>
                    <el-table-column prop="levelName" align="center" label="等级" :show-overflow-tooltip="true" sortable>
                    </el-table-column>
                    <el-table-column prop="storeName" align="center" label="门店" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="studyMinutes" align="center" label="学习时长" :show-overflow-tooltip="true" sortable>
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: center">
                                {{ scope.row.studyMinutes | stime }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address"  align="center" label="操作">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: center">
                                <el-link type="primary" :underline="false" @click='details(scope.row)'>查看详情</el-link>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
              
            </div>

        </div>
    </div>
</template>

<script>
import { Loading } from 'element-ui';
import {levelData} from '../utils/levelData'
import { getStatistic, storepageList, homebeauticianList, agentData, agentData2, agentTrainees, levelList } from '../apis/index'
export default {
    data() {
        return {
            isPc:true,
            levelId: '',
            levelList: [],
            levelSortList: [
                {
                    id: 1,
                    name: '升序'
                },
                {
                    id: 2,
                    name: '降序'
                },
            ],
            levelSortId: '',
            timeList: [
                {
                    id: 1,
                    name: '升序'
                },
                {
                    id: 2,
                    name: '降序'
                },
            ],
            timeId: '',
            num: 0,
            datas: {},
            agentUserId: '',
            timeScope: [],
            timeScope2: [],
            lowestStudyTimeMinute: '',
            highestStudyTimeMinute: '',
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            storeId: '',
            storeList: [],
            brandList: [],
            brandId: 1,
            tableData: [

            ],
            questionCount: 0,
            contentVideoCount: 0,
            contentRichTextCount: 0,
            traineeCount: 0,
            traineeCount: 0,
            mentorCount: 0
        };
    },
    watch:{
        levelSortId(newData,oldData){
            if(newData){
                this.timeId=''
            }
        },
        timeId(newData,oldData){
            if(newData){
                this.levelSortId=''
            }
        },
    },
    filters: {
        stime(value) {
            return parseInt(value / 60) + '小时' + value % 60 + '分'
        }
    },
    mounted() {
        // this.init();
        // this.getMap().then(res => {
        //     this.init2();
        //     this.init3();
        // })

    },
   
    created() {
        let width=window.screen.width
        if(width>400){
            this.isPc=true
        }else{
            this.isPc=false
        }
        // this.onclick()
        // this.brandList = this.$store.state.brandList
        // this.store()
        this.getCurrentTime()
        this.getAgentData()
        this.getStuentNum()
        this.list()
        this.levelList=levelData
        // this.leave()
    },
    methods: {
        levelSort(column){
            console.log(column)
            if(column.prop=='levelName'){
                
                if(column.order=='ascending'){//升序
                    this.levelSortId=1
                    this.list()
                }else if(column.order=='descending'){//降序
                    this.levelSortId=2
                    this.list()
                }else{
                    this.levelSortId=''
                    this.list()
                }
            }else if(column.prop=='studyTimeMinutes'){
                
                if(column.order=='ascending'){//升序
                    this.timeId=1
                    this.list()
                }else if(column.order=='descending'){//降序
                    this.timeId=2
                    this.list()
                }else{
                    this.timeId=''
                    this.list()
                }
            }
            // if(column.prop=='studyTimeMinutes'){
            //     if(column.order=='ascending'){//升序
            //         console.log('我是升序')
            //         this.timeId=1
            //         this.list()
            //     }else if(column.order=='descending'){//降序
            //         console.log('我是降序')
            //         this.timeId=2
            //         this.list()
            //     }else{
            //         this.timeId=''
            //         this.list()
            //     }
            // }
        },
        searchKeywords(){
            this.$router.push('/searchs')
        },
        exit(){//退出
            this.$confirm('确认退出, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            this.$store.commit('setToken','')
          this.$store.commit('setPermissionsList','')
          this.$store.commit('setIndex',0)
          this.$message.success('退出成功')
          setTimeout(() => {
             window.location.href='/login'
          }, 500);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出'
          });          
        });
        },
        async leave() {//获取等级列表
            const { data } = await levelList({ roleId: 3 })
            if (data.code == 200) {

                this.levelList = data.data
            } else {
                this.$message.error(data.msg)
            }
        },
        search() {
            this.getAgentData()
            this.getStuentNum()
            this.list()

        },
        getCurrentTime() {//默认时间范围
            let date = new Date()
            let year = date.getFullYear()
            let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
            let day = date.getDate() + 1 < 10 ? '0' + date.getDate() : date.getDate()
            this.timeScope[0] = `${year}-01-01`
            this.timeScope[1] = `${year}-${month}-${day}`
            this.timeScope2[0] = `${year}-01-01`
            this.timeScope2[1] = `${year}-${month}-${day}`
            return `${year}-${month}-${day}`
        },
        async list() {
            const params = {
                startTime: this.timeScope2[0],
                endTime: this.timeScope2[1],
                lowestStudyTimeMinute: this.lowestStudyTimeMinute,
                highestStudyTimeMinute: this.highestStudyTimeMinute,
                levelId: this.levelId,
                agentUserId:this.$store.state.userId
            }
            const { data } = await agentTrainees(params)
            if (data.code == 200) {
                let list = data.data
                let levelSortId = this.levelSortId
                let timeId = this.timeId
                if (this.levelSortId == 1) {//等级
                    list = list.sort(this.sortLevelTop('levelId'))
                } else if (this.levelSortId == 2) {
                    list = list.sort(this.sortLevelBottom('levelId'))
                }
                if (this.timeId == 1) {//学习时长
                    list = list.sort(this.sortLevelTop('studyMinutes'))
                } else if (this.timeId == 2) {
                    list = list.sort(this.sortLevelBottom('studyMinutes'))
                    
                }
                this.tableData = list
            }
        },
        sortLevelTop: function (prop) {//升序
            return function (obj1, obj2) {
                var val1 = obj1[prop]
                var val2 = obj2[prop]
                if (val1 < val2) {
                    return -1
                } else if (val1 > val2) {
                    return 1
                } else {
                    return 0
                }
            }
        },
        sortLevelBottom: function (prop) {//降序
            return function (obj1, obj2) {
                var val1 = obj2[prop]
                var val2 = obj1[prop]
                if (val1 < val2) {
                    return -1
                } else if (val1 > val2) {
                    return 1
                } else {
                    return 0
                }
            }
        },

        async getAgentData() {//获取用户以及会员数量
            const params = {
                agentUserId:this.$store.state.userId,
                endTime: this.timeScope[1],
                startTime: this.timeScope[0],
            }
            console.log(params)
            const { data } = await agentData(params)
            if (data.code == 200) {
                this.datas = data.data
            }
        },
        async getStuentNum() {//获取学习5小时用户数量
            const params = {
                lowestStudyTimeMinute: 300,
                endTime: this.timeScope[1],
                startTime: this.timeScope[0],
                agentUserId:this.$store.state.userId
            }
            const { data } = await agentData2(params)
            if (data.code == 200) {
                this.num = data.data
            }
        },
        details(row) {//查看详情
            this.$router.push({ path: '/beauticiandetails2', query: { row: JSON.stringify(row) } })
        },
        handleSizeChange(val) {
            this.pageSize = val
            console.log(`每页 ${val} 条`);
            this.list()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            console.log(`当前页: ${val}`);
            this.list()
        },

        async store() {//获取门店列表
            const params = {
                pageIndex: 1,
                pageSize: 9999
            }
            const { data } = await storepageList(params)
            if (data.code == 200) {
                this.storeList = data.data.records
            } else {
                this.$message.error(data.msg)
            }
        },
        go1() {
            this.$store.commit('setIndex', 6)
            this.$router.push({ path: '/textIndex' })
        },
        go2() {
            this.$store.commit('setIndex', 3)
            this.$router.push({ path: '/people' })
        },
        go3() {
            this.$store.commit('setIndex', 2)
            this.$router.push({ path: '/exercises' })
        },
        go4() {
            this.$store.commit('setIndex', 1)
            this.$router.push({ path: '/content' })
        },
        async getMap() {//获取图表数据信息
            const { data } = await getStatistic()
            if (data.code == 200) {
                console.log(data.data)
                const { questionCount, contentVideoCount, contentRichTextCount, traineeCount, mentorCount, traineeVipCount, traineeMaxLevelCount } = data.data
                this.questionCount = questionCount
                this.contentVideoCount = contentVideoCount
                this.contentRichTextCount = contentRichTextCount
                this.traineeCount = traineeCount
                this.traineeCount = traineeCount
                this.mentorCount = mentorCount
                this.traineeVipCount = traineeVipCount
                this.traineeMaxLevelCount = traineeMaxLevelCount
            } else {
                this.$message.error(data.msg)
            }

        },
        init() {
            //2.初始化
            this.chart = this.$Echarts.init(this.$refs.chart);
            //3.配置数据
            var option = {
                // title: {
                //   text: 'Referer of a Website',
                //   subtext: 'Fake Data',
                //   left: 'center'
                // },
                tooltip: {
                    trigger: "item",
                },
                // legend: {
                //   orient: 'vertical',
                //   left: 'left'
                // },
                series: [
                    {
                        // name: 'Access From',
                        type: "pie",
                        // radius: "50%",
                        label: {
                            normal: {
                                position: "inner",
                                show: false,
                            },
                        },
                        data: [
                            { value: 1000, name: "已完成", itemStyle: { color: "#68DDD5" } },
                            { value: 100, name: "未完成", itemStyle: { color: "#FFE692" } },
                        ],
                        // emphasis: {
                        //   itemStyle: {
                        //     shadowBlur: 10,
                        //     shadowOffsetX: 0,
                        //     shadowColor: "rgba(0, 0, 0, 0.5)",
                        //   },
                        // },
                    },
                ],
            };

            // 4.传入数据
            this.chart.setOption(option);
        },
        // onclick(){
        //   let options={
        //     text:'拼命加载中'
        //   }
        //     let loadingInstance = Loading.service(options);
        //     setTimeout(() => {
        //       this.init2()
        //       loadingInstance.close();
        //     }, 1000);

        // },
        init2() {
            //2.初始化
            this.chart = this.$Echarts.init(this.$refs.chart2);
            //3.配置数据
            var option = {
                // title: {
                //   text: 'Referer of a Website',
                //   subtext: 'Fake Data',
                //   left: 'center'
                // },
                tooltip: {
                    trigger: "item",
                },
                // legend: {
                //   orient: 'vertical',
                //   left: 'left'
                // },
                series: [
                    {
                        // name: 'Access From',
                        type: "pie",
                        // radius: "50%",
                        label: {
                            normal: {
                                position: "inner",
                                show: false,
                            },
                        },
                        data: [
                            { value: this.questionCount, name: "题目数量", itemStyle: { color: "#ffe692" }, },
                            { value: this.contentVideoCount, name: "视频数量", itemStyle: { color: "#85FFF7" } },
                        ],
                        // emphasis: {
                        //   itemStyle: {
                        //     shadowBlur: 10,
                        //     shadowOffsetX: 0,
                        //     shadowColor: "rgba(0, 0, 0, 0.5)",
                        //   },
                        // },
                    },
                ],
            };
            // 4.传入数据
            this.chart.setOption(option);
        },
        init3() {
            //2.初始化
            this.chart = this.$Echarts.init(this.$refs.chart3);
            //3.配置数据
            var option = {
                // title: {
                //   text: 'Referer of a Website',
                //   subtext: 'Fake Data',
                //   left: 'center'
                // },
                tooltip: {
                    trigger: "item",
                },
                // legend: {
                //   orient: 'vertical',
                //   left: 'left'
                // },
                series: [
                    {
                        // name: 'Access From',
                        type: "pie",
                        // radius: "50%",
                        label: {
                            normal: {
                                position: "inner",
                                show: false,
                            },
                        },
                        data: [
                            { value: this.traineeCount, name: "学员数量", itemStyle: { color: "#FF8D9F" }, },
                            { value: this.traineeCount, name: "用户数量", itemStyle: { color: "#79DAFE" } },
                        ],
                        // emphasis: {
                        //   itemStyle: {
                        //     shadowBlur: 10,
                        //     shadowOffsetX: 0,
                        //     shadowColor: "rgba(0, 0, 0, 0.5)",
                        //   },
                        // },
                    },
                ],
            };
            // 4.传入数据
            this.chart.setOption(option);
        },
    },
};
</script>

<style lang='less' scoped>
.main {
    display: flex;
    justify-content: space-between;

    img {
        width: 290px;
    }
}

.item {
    display: flex;
    align-items: center;
    // width: 290px;
    width: 24%;
    height: 143px;
    border-radius: 20px;
    justify-content: space-evenly;
    color: white;

    img {
        width: 88px;
        height: 88px;
    }

    div {
        display: flex;
    }
}

.backwriting {
    background: #fe7e92;
}

.backteacher {
    background: #7d95ff;
}

.backtopic {
    background: #4eded4;
}

.backlearning {
    background: #57d1fe;
}

.fs {
    font-size: 19px;
    line-height: 60px;
}

.fl {
    font-size: 33px;
}

.dataimg {
    margin: 26px 0;
    display: flex;
    justify-content: space-between;
}

.left {
    // width: 60%;
    width: 764px;
    background: #fff;
    // height: 100%;
    height: 463px;
    display: flex;
    // align-items: center;
    flex-direction: column;
}

.left>div:last-child {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    // flex-direction: column;
    // height: 300;
}

.left>div:last-child>div {
    display: flex;
    font-size: 14px;
    color: #000;
    flex-direction: column;
}

.end {
    width: 18px;
    height: 18px;
    background: #ffe692;
    border-radius: 10px;
    margin-right: 10px;
}

.unfinisher {
    width: 18px;
    height: 18px;
    background: #FF8D9F;
    border-radius: 10px;
    margin-right: 10px;
}

.title {
    display: flex;
    margin: 18px;
    align-items: center;
}

.title>div:first-child {
    width: 9px;
    height: 22px;
    border-radius: 10px;
    background: #80dfff;
    margin-right: 10px;
}

.title>div:last-child {
    font-size: 18px;
    font-weight: bold;
    color: #000;
}

.lefttext>div:nth-child(2) {
    margin: 35px 0;
}

.lefttext {
    // align-items: center;
}

.bottom {
    border-radius: 14px;
    padding: 19px 18px;
    background: #fff;
}

.line {
    width: 9px;
    height: 22px;
    background: #72daff;
    border-radius: 6px;
}

.bordertitle {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 14px;
}

.bordertitle>div:first-child {
    display: flex;
}

.bordertitle>div:first-child>div:first-child {
    margin-right: 11px;
}

.cinput /deep/.el-input__inner {
    height: 34px;
    line-height: 10px;
}

.ac /deep/.el-input__inner {
    border: none;
    background: #f7f7f7;
    // margin-right: 11px;
}
</style>
