export const levelData=[
    {
        levelId:1,
        levelName:'答应',
        step:'层级一',
        experience:120
    },
    {
        levelId:2,
        levelName:'常在',
        step:'层级一',
        experience:200
    },
    {
        levelId:3,
        levelName:'贵人',
        step:'层级一',
        experience:300
    },
    {
        levelId:4,
        levelName:'嫔',
        step:'层级一',
        experience:420
    },
    {
        levelId:5,
        levelName:'妃',
        step:'层级一',
        experience:570
    },
    {
        levelId:6,
        levelName:'贵妃',
        step:'层级一',
        experience:750
    },
    {
        levelId:7,
        levelName:'皇贵妃',
        step:'层级一',
        experience:1000
    },
    {
        levelId:8,
        levelName:'皇后',
        step:'层级一',
        experience:1280
    },

    {
        levelId:21,
        levelName:'御史',
        step:'层级二',
        experience:1500
    },
    {
        levelId:22,
        levelName:'尚书',
        step:'层级二',
        experience:1800
    },
    {
        levelId:23,
        levelName:'太傅',
        step:'层级二',
        experience:2180
    },
    {
        levelId:24,
        levelName:'将军',
        step:'层级二',
        experience:2580
    },
    {
        levelId:25,
        levelName:'丞相',
        step:'层级二',
        experience:3080
    },
    {
        levelId:26,
        levelName:'皇上',
        step:'层级二',
        experience:3700
    },
    {
        levelId:31,
        levelName:'上仙',
        step:'层级三',
        experience:4500
    },
    {
        levelId:32,
        levelName:'上神',
        step:'层级三',
        experience:5500
    },
    {
        levelId:33,
        levelName:'真神',
        step:'层级三',
        experience:6200
    },
    {
        levelId:34,
        levelName:'帝君',
        step:'层级三',
        experience:9999
    },
]